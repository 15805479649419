
<template>
  <div>
    <CCard accentColor="primary">
      <CCardBody>
        <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
          <CForm @submit.stop.prevent="handleSubmit(submit)">
            <CRow>
              <CCol md="3">
                <validation-provider rules="required" v-slot="{ errors }" name="No. Usulan">
                  <CInput
                    label="No. Usulan"
                    placeholder="No. Usulan"
                    v-model="form.no_surat"
                    :invalid-feedback="errors[0]"
                    :isValid="!errors[0]"
                    :disabled="formDisabled"
                  ></CInput>
                </validation-provider>
              </CCol>
              <CCol md="3">
                <validation-provider rules="required" v-slot="{ errors }" name="Tanggal Usulan">
                  <div role="group" class="form-group">
                    <label class>Tanggal Usulan</label>
                    <datepicker
                      placeholder="Tanggal Usulan"
                      v-model="form.tgl_surat"
                      :disabled="formDisabled"
                      :bootstrap-styling="true"
                      :input-class="'datePicker'"
                      :class="[
                        { 'is-valid': !errors[0] },
                        { 'is-invalid': errors[0] },
                      ]"
                    ></datepicker>
                    <div class="invalid-feedback" v-if="errors[0]">{{ errors[0] }}</div>
                  </div>
                </validation-provider>
              </CCol>
              <!-- <CCol md="3">
                <validation-provider
                  rules="required"
                  v-slot="{ errors }"
                  name="Lampiran"
                >
                  <CInput
                    label="Lampiran"
                    placeholder="Lampiran"
                    v-model="form.keterangan"
                    :invalid-feedback="errors[0]"
                    :isValid="!errors[0]"
                  ></CInput>
                </validation-provider>
              </CCol>-->
            </CRow>
            <CRow>
              <CCol md="6">
                <validation-provider rules="required" v-slot="{ errors }" name="Instansi">
                  <div role="group" class="form-group">
                    <label class>Instansi</label>
                    <v-select
                      v-model="defaultSelected"
                      item-text="nama_instansi"
                      item-value="id_instansi"
                      disabled
                      :options="optionsInstansi"
                      label="nama_instansi"
                      :filterable="false"
                      @search="onSearchInstansi"
                      :class="[
                        { 'is-valid': !errors[0] },
                        { 'is-invalid': errors[0] },
                      ]"
                    ></v-select>
                    <div class="invalid-feedback" v-if="errors[0]">{{ errors[0] }}</div>
                  </div>
                </validation-provider>
              </CCol>
            </CRow>
            <CRow>
              <CCol md="12">
                <validation-provider rules="required" v-slot="{ errors }" name="Unit Kerja">
                  <div role="group" class="form-group">
                    <label class>OPD</label>
                    <v-select
                      v-model="form.id_unit_kerja"
                      :options="optionsOPD"
                      label="nama_opd"
                      :reduce="opd => opd.id_opd"
                      :filterable="false"
                      :disabled="formDisabled"
                      @search="onSearchOPD"
                      :class="[
                        { 'is-valid': !errors[0] },
                        { 'is-invalid': errors[0] },
                      ]"
                    ></v-select>
                    <div class="invalid-feedback" v-if="errors[0]">{{ errors[0] }}</div>
                  </div>
                </validation-provider>
              </CCol>
            </CRow>
            <CRow v-if="!formDisabled">
              <CCol>
                <CButtonGroup size="sm" class="flex-row-reverse d-flex">
                  <CButton class="col-md-2" color="info" @click="addDetails">
                    <CIcon name="cil-plus" />Tambah Pegawai
                  </CButton>
                </CButtonGroup>
              </CCol>
            </CRow>
            <hr />
            <template v-for="(data, index) in form.details">
              <fieldset class="form-group border p-3" :key="index" v-if="data.is_deleted == 0">
                <div small style :key="componentKey">
                  <CRow v-if="!formDisabled">
                    <CCol>
                      <CButtonGroup size="sm" class="flex-row-reverse d-flex">
                        <CButton class="col-md-1" color="danger" @click="deleteDetails(index)">Hapus</CButton>
                      </CButtonGroup>
                    </CCol>
                  </CRow>
                  <CRow>
                    <CCol md="6">
                      <validation-provider rules="required" v-slot="{ errors }" name="NIP - Nama">
                        <div role="group" class="form-group">
                          <label class>NIP - Nama</label>
                          <v-select
                            :key="'select_' + componentKey + index"
                            v-model="data.petugas_array"
                            :options="optionsPegawai"
                            label="nama"
                            :disabled="formDisabled"
                            :filterable="true"
                            @search="onSearchPegawai"
                            @input="onChangesPegawai(index, $event)"
                            :class="[
                              { 'is-valid': !errors[0] },
                              { 'is-invalid': errors[0] },
                            ]"
                          ></v-select>
                          <div class="invalid-feedback" v-if="errors[0]">{{ errors[0] }}</div>
                        </div>
                      </validation-provider>
                    </CCol>
                  </CRow>
                  <CRow>
                    <CCol md="6">
                      <validation-provider rules="required" v-slot="{ errors }" name="NIP - Nama">
                        <div role="group" class="form-group">
                          <label class>Jenis Pensiun</label>
                          <v-select
                            v-model="data.jenis_pelayanan_array"
                            :options="optionsJenisLayanan"
                            label="nama_layanan"
                            :disabled="formDisabled"
                            :filterable="true"
                            @input="onChangesJenisLayanan(index, $event)"
                            :class="[
                              { 'is-valid': !errors[0] },
                              { 'is-invalid': errors[0] },
                            ]"
                          ></v-select>
                          <div class="invalid-feedback" v-if="errors[0]">{{ errors[0] }}</div>
                        </div>
                      </validation-provider>
                    </CCol>
                  </CRow>
                  <!---->
                  <fieldset class="form-group border p-3" v-if="data.informasi_pegawai">
                    <legend class="w-auto px-2">Informasi Pegawai</legend>

                    <CRow>
                      <CCol md="6">
                        <CInput
                          label="Jenis Kelamin"
                          placeholder
                          readonly
                          v-model="data.informasi_pegawai.jenis_kelamin"
                        ></CInput>
                        <CInput
                          label="Jabatan"
                          placeholder
                          readonly
                          v-model="data.informasi_pegawai.nama_jabatan"
                        ></CInput>
                        <CInput
                          label="Nama Unit Kerja"
                          placeholder
                          readonly
                          v-model="data.informasi_pegawai.nama_unit_kerja"
                        ></CInput>
                        <CInput
                          label="Golongan Ruang"
                          placeholder
                          readonly
                          v-model="
                            data.informasi_pegawai.pangkat_golongan_ruang
                          "
                        ></CInput>
                      </CCol>
                      <CCol md="6">
                        <CInput
                          label="Gaji Pokok"
                          placeholder
                          readonly
                          v-model="data.informasi_pegawai.gaji_pokok"
                        ></CInput>
                        <CInput
                          label="TMT Pangkat"
                          placeholder
                          readonly
                          v-model="data.informasi_pegawai.tmt_pangkat"
                        ></CInput>
                        <CInput
                          label="Masa Jabatan"
                          placeholder
                          readonly
                          v-model="data.informasi_pegawai.masa_jabatan"
                        ></CInput>
                        <CInput
                          label="Umur"
                          placeholder
                          readonly
                          v-model="data.informasi_pegawai.umur"
                        ></CInput>
                      </CCol>
                    </CRow>
                  </fieldset>
                  <div class="position-relative table-responsive">
                    <span style="font-size: 12px; color: red;"><b>Max file upload 1MB</b></span>
                    <table class="table table-hover table-bordered">
                      <thead>
                        <tr>
                          <th class style="vertical-align: middle; overflow: hidden; width: 4%">
                            <div>No</div>
                            <!---->
                          </th>
                          <th class style="vertical-align: middle; overflow: hidden; width: 51%">
                            <div>Jenis Dokumen</div>
                            <!---->
                          </th>
                          <th class style="vertical-align: middle; overflow: hidden; width: 14%">
                            <div>Format File</div>
                            <!---->
                          </th>
                          <th class style="vertical-align: middle; overflow: hidden; width: 31%">
                            <div>File Upload</div>
                            <!---->
                          </th>
                        </tr>
                        <!---->
                      </thead>
                      <tbody class="position-relative">
                        <tr v-for="(dataUpload, index2) in data.upload_detail" :key="index2">
                          <td>{{ index2 + 1 }}</td>
                          <td>{{ dataUpload.nama_dokumen }}</td>
                          <td>{{ dataUpload.format_file }}</td>
                          <td style="width: 20%">
                            <CInputFile
                              label="File input"
                              @change="uploadFile($event, index, index2)"
                              :isValid="true"
                              custom
                              v-if="
                                !dataUpload.upload_dokumen &&
                                !dataUpload.loadingFile
                              "
                            />
                            <CSpinner color="success" size="sm" v-if="dataUpload.loadingFile" />
                            <CButtonGroup size="sm" v-if="dataUpload.upload_dokumen">
                              <CButton
                                color="success"
                                class="px-4"
                                @click="downloadFile(index, index2)"
                              >Download File</CButton>
                              <CButton
                                color="danger"
                                class="px-4"
                                v-if="!formDisabled"
                                @click="deleteFile(index, index2)"
                              >Hapus File</CButton>
                            </CButtonGroup>
                            <div v-if="dataUpload.errorUploadExist" style="font-size: 10px; color: red; margin-top: -14px; margin-bottom: -4px;">File yang diupload melebihi size yang telah ditentukan</div>
                          </td>
                        </tr>
                      </tbody>
                      <!---->
                    </table>
                    <!---->
                  </div>
                  <!---->
                </div>
              </fieldset>
            </template>
            <CRow>
              <CCol col="6" class="text-left">
                <CSpinner color="success" size="sm" v-if="loading" />
                <CButton color="success" class="px-4" @click="CloseForm()">Kembali</CButton>
                &nbsp;
                <CButton color="primary" class="px-4" type="submit" v-show="!formDisabled">Simpan</CButton>
              </CCol>
              <CCol col="6" class="text-right">
                <!-- <CButton color="link" class="px-0">Forgot password?</CButton>
                <CButton color="link" class="d-lg-none">Register now!</CButton>-->
              </CCol>
            </CRow>
          </CForm>
        </ValidationObserver>
      </CCardBody>
    </CCard>
  </div>
</template>
<script>
import Swal from "sweetalert2";
import VSelect from "vue-select";
import _ from "lodash";
import Datepicker from "vuejs-datepicker";
import axios from "axios";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { mapGetters } from "vuex";

export default {
  name: "FormUsulanPenerbitanSKPensiun",
  components: {
    VSelect,
    ValidationProvider,
    ValidationObserver,
    Datepicker,
  },
  data() {
    return {
      errorMessage: null,
      optionsPgw: [],
      optionsInstansi: [],
      optionsUnitKerja: [],
      optionsOPD: [],
      optionsPegawai: [],
      optionsJenisLayanan: [],
      formVisibility: false,
      defaultSelected: {
        nama_instansi: "Purwakarta",
        id_instansi: 3214,
      },
      form: {
        nama_instansi: "Purwakarta",
        id_instansi: 3214,
        id_unit_kerja: null,
        id_opd: null,
        nip: null,
        no_surat: null,
        tgl_surat: null,
        details: [],
      },
      loading: false,
      item: null,
      componentKey: 0,
      formDisabled: false
    };
  },
  computed: {
    ...mapGetters("auth", { user: "user" }),
  },
  mounted() { },
  watch: {
    item: function (item) {
      this.form.id_instansi = null;
      this.form.id_unit_kerja = null;
      this.form.id_opd = null;
      this.form.nama_opd = null
      this.form.nip = null;
      this.form.no_surat = null;
      this.form.tgl_surat = null;
      this.optionsInstansi = [];
      this.optionsUnitKerja = [];
      this.optionsOPD = [];
      this.form.details = [];
      if (item) {
        this.form.id_instansi = item.id_instansi.toString();
        this.form.id_unit_kerja = item.id_unit_kerja;
        this.form.id_opd = item.id_opd;
        this.form.nama_opd = item.nama_opd;
        this.form.nip = item.nip;
        this.form.no_surat = item.no_surat;
        this.form.tgl_surat = item.tgl_surat;
        this.form.keterangan = item.keterangan;
        const array = [];
        array.push(item.mst_instansi);
        this.optionsInstansi = array;
        const array2 = [];
        array2.push({
          id_opd: item.id_unit_kerja,
          nama_opd: item.nama_unit_kerja
        });
        this.optionsOPD = array2;

        item.pelayanan_detail.forEach((element) => {
          const arrayPegawai = [];
          arrayPegawai.push({
            nip: element.d_identitas.nip,
            nama: element.d_identitas.nip + " - " + element.d_identitas.nama,
          });
          this.form.details.push({
            id: element.id,
            nip: element.d_identitas.nip,
            ref_jenis_layanan_id: element.ref_jenis_layanan_id,
            ref_persyaratan_pelayanan_id: element.ref_persyaratan_pelayanan_id,
            upload_file: element.upload_file,
            jenis_pelayanan_array: element.ref_jenis_pelayanan,
            petugas_array: arrayPegawai,
            optionsPgw: arrayPegawai,
            informasi_pegawai: element.d_identitas.v_detail_informasi_pegawai,
            upload_detail: element.pelayanan_detail_upload,
            is_deleted: 0,
          });
        });
      }
    },
  },
  methods: {
    async uploadFile(files, index, index2) {
      if (this.form.details[index]['nip'] == null) return false;
      console.log(files[0].size + ' bytes');
      console.log(this.form.details[index]["upload_detail"]);
      var sizeMb = (files[0].size / (1024*1024)).toFixed(2);
      if (sizeMb > 1) {
        this.form.details[index]["upload_detail"][index2]["errorUploadExist"] = true;
        return false;
      }
      const file = new FormData();
      file.append("files", files[0]);
      file.append("path", 'dokumen-pegawai');
      file.append("name_file", this.form.details[index]["upload_detail"][index2]["nama_dokumen"] + '_' + this.form.details[index]['nip']);
      try {
        this.form.details[index]["upload_detail"][index2]["loadingFile"] = true;
        this.componentKey += 1;
        let { data } = await axios.post("/api/upload-document", file);
        this.form.details[index]["upload_detail"][index2]["upload_dokumen"] =
          data.url;
      } catch (error) {
        console.log(error);
        this.form.details[index]["upload_detail"][index2]["upload_dokumen"] =
          null;
      } finally {
        this.form.details[index]["upload_detail"][index2][
          "loadingFile"
        ] = false;
        this.componentKey += 1;
      }
    },
    async downloadFile(index, index2) {
      try {
        this.loading = true;
        var url = this.form.details[index]["upload_detail"][index2]["upload_dokumen"].split('/storage/');
        let response = await this.$store.dispatch("pelayanan/download", {
            path: url[1]
          }
        );
        var fileURL = window.URL.createObjectURL(new Blob([response.data]));
        var fileLink = document.createElement("a");
        fileLink.href = fileURL;
        fileLink.setAttribute(
          "download",
          url[1].replace('dokumen-pegawai/', '')
        );
        document.body.appendChild(fileLink);
        fileLink.click();
      } catch (ex) {
        this.items = [];
      } finally {
        this.loading = false;
      }
    },
    deleteFile(index, index2) {
      this.form.details[index]["upload_detail"][index2]["upload_dokumen"] =
        null;
    },
    async onSearch(index, search) {
      // loading(true);
      await this.search(index, search, this);
      // loading(false);
    },
    search: _.debounce(async (search, vm) => {
      let options = await vm.$store.dispatch(
        "d_identitas/autocomplete_opd_pegawai", {
          search: search,
          id_opd: vm.form.id_opd
        }
      );
      if (search) vm.optionsPegawai = options;
    }, 300),
    async searchLayanan(search) {
      let options = await this.$store.dispatch(
        "jenis_layanan/autoCompleteSearch",
        search
      );
      this.optionsJenisLayanan = options;
    },
    async submit() {
      const vm = this;
      try {
        this.loading = true;
        var form = { ...this.form };
        form.details.forEach((value) => {
         
          value.optionsPgw = null
          value.informasi_pegawai = null
        });
        let { status } = this.item
          ? await this.$store.dispatch("pelayanan/update", {
            data: form,
            id: vm.item.id,
          })
          : await this.$store.dispatch("pelayanan/store", form);
        if (status >= 200 && status <= 202) {
          Swal.fire({
            title: "Sukses",
            text: "Data berhasil tersimpan!",
            icon: "success",
            showCancelButton: false,
            confirmButtonColor: "#3085d6",
            confirmButtonText: "Tutup!",
          }).then(() => {
            if (!vm.item) {
              requestAnimationFrame(() => {
                this.$refs.observer.reset();
              });
            }
            vm.$emit("done");
          });
        }
      } catch (x) {
        console.log(x);
      } finally {
        this.loading = false;
      }
    },
    showModalFormPilih(item) {
      this.$refs.formPilih.toggle(item, this.item);
    },
    populateInput(item, formDisabled) {
      (this.defaultSelected = {
        nama_instansi: "Purwakarta",
        id_instansi: 3214,
      }),
        (this.form = {
          nama_instansi: "Purwakarta",
          id_instansi: 3214,
          id_unit_kerja: null,
          nip: null,
          id_opd: null,
          no_surat: null,
          tgl_surat: null,
          keterangan: null,
          details: [],
        });
      this.searchLayanan("pensiun", this);
      if (this.$store.state.auth.user.role.superadmin != 1) this.searchOPD("", this);
      this.item = item;
      this.formDisabled = formDisabled
    },
    async onSearchInstansi(search, loading) {
      loading(true);
      await this.searchInstansi(search, this);
      loading(false);
    },
    searchInstansi: _.debounce(async (search, vm) => {
      let options = await vm.$store.dispatch(
        "master_instansi/autocomplete",
        search
      );
      if (search) vm.optionsInstansi = options;
    }, 300),
    async onSearchOPD(search, loading) {
      loading(true)
      await this.searchOPD(search, this);
      loading(false)
    },
    searchOPD: _.debounce(async (search, vm) => {
      if (vm.form.id_unit_kerja != null) return false;
      if (vm.$store.state.auth.user.role.superadmin == 1) {
        var options = await vm.$store.dispatch(
          "master_unit_kerja/autocomplete_opd",
          search
        );
      } else {
        var options = await vm.$store.dispatch(
          "master_unit_kerja/autocompleteOpdByUser",
          search
        );
      }
      if (options) vm.optionsOPD = options;
    }, 300),
    async onSearchPegawai(search, loading) {
        loading(true);
        await this.SearchPegawai(search, this);
        loading(false);
      },
    SearchPegawai: _.debounce(async (search, vm) => {
      let options = await vm.$store.dispatch(
        "d_identitas/autocomplete_opd_pegawai", {
          search: search,
          id_opd: vm.form.id_unit_kerja
        }
      );
      if (search) vm.optionsPegawai = options;
    }, 300),
    async onSearchUnitKerja(search, loading) {
      loading(true);
      await this.searchUnitKerja(search, this);
      loading(false);
    },
    searchUnitKerja: _.debounce(async (search, vm) => {
      let options = await vm.$store.dispatch("master_unit_kerja/autocomplete", {
        search: search,
        id_instansi: vm.defaultSelected.id_instansi,
        parent: "",
      });
      if (search) vm.optionsUnitKerja = options;
    }, 300),
    addDetails() {
      this.form.details.push({
        id: null,
        nip: null,
        ref_jenis_layanan_id: null,
        ref_persyaratan_pelayanan_id: null,
        upload_file: null,
        jenis_pelayanan_array: [],
        petugas_array: [],
        optionsPgw: [],
        informasi_pegawai: null,
        upload_detail: [],
        is_deleted: 0,
      });
    },
    deleteDetails(index) {
      this.form.details[index]["is_deleted"] = 1;
    },
    onChangesJenisLayanan(index, value) {
      this.form.details[index].ref_jenis_layanan_id = value.id;
      const me = this;

      me.form.details[index]["upload_detail"] = [];
      value.ref_persyaratan_pelayanan.forEach(async (element) => {
        let document = await me.$store.dispatch(
          "d_identitas/document",
          {
            nip: me.form.details[index]['nip'],
            id: element.id
          }
        );
        console.log(document)
        me.form.details[index]["upload_detail"].push({
          id: null,
          upload_dokumen: document.file_path,
          ref_persyaratan_pelayanan_id: element.id,
          nama_dokumen: element.nama_dokumen,
          format_file: element.format_file,
          loadingFile: false,
          errorUploadExist: false
        });
      });
      this.componentKey += 1;
    },
    onChangesPegawai(index, value) {
      this.form.details[index].informasi_pegawai =
        value.v_detail_informasi_pegawai;
      this.form.details[index].nip = value.nip;
      this.componentKey += 1;
    },
    openNewForm() {
      this.formVisibility = !this.formVisibility;
      this.$refs.formInput.populateInput(null);
    },
    CloseForm() {
      this.$emit("clicked", !this.formVisibility);
    },
  },
};
</script>